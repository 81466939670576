import React from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { PageMenuTemplate, AccordionTemplates, Spinner } from '../../components'
import pageTemplateMobileView from '../../helpers/page-template-mobile'
import './key-information-styles.scss'

const KeyInformationView = ({
  data,
  loading,
  dataFiltered,
  onHandelFilterByGroup,
}) => {
  const breakpoints = useBreakpoint()
  const { t, i18n } = useTranslation()

  if (loading) {
    return <Spinner loading={loading} />
  }
  if (breakpoints.sm) {
    return (
      <div className="KeyInformation__container">
        <div className="page-title">{t('keyInfo.title')}</div>
        <AccordionTemplates
          t={t}
          i18n={i18n}
          data={pageTemplateMobileView(data.content)}
        />
      </div>
    )
  }

  return (
    <PageMenuTemplate
      title={t('keyInfo.title')}
      data={dataFiltered}
      menu={_.get(data, 'tab')}
      onHandelClick={onHandelFilterByGroup}
      param={'key_info'}
      i18n={i18n}
      t={t}
    />
  )
}

export default KeyInformationView
