import _ from 'lodash'

const transforContent = (data) => _.map(data, (item) => ({
  title: item.question,
  content: item.answer,
}))

const pageTemplateMobileView = (data) => _.map(data, (item, index) => ({
  title: item.name,
  open: false,
  isOpenByDefault: index === 0,
  children: transforContent(item.key_info),
}))

export default pageTemplateMobileView
